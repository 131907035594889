import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const BtnCircle = props => {
  return (
    <div className="mb-1 is-flex is-justify-content-flex-end">
      <div className="is-flex-grow-1"></div>
      <Link className="is-flex has-arrow-hover" to={props.path}>
        <div className="is-size-7 has-letter-spacing-1 is-align-self-center">
          <p>{props.description}</p>
        </div>
        <div className="mt-4px ml-3 has-opacity-8 has-border-circle">
          <StaticImage
            src="../images/arrow-right-thin.svg"
            alt="Arrow right"
            placeholder="blurred"
            layout="fixed"
            height={18}
            className="btn-arrow"
          />
        </div>
      </Link>
    </div>
  )
}

export default BtnCircle
