import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const BtnLeft = props => {
  return (
    <Link to={props.path}>
      <div className="is-flex is-justify-content-flex-start mb-1">
        <div className="pt-1 mr-3 has-opacity-8 is-align-self-center">
          <StaticImage
            src="../images/arrow-left-thin.svg"
            alt="Arrow left"
            placeholder="blurred"
            layout="fixed"
            height={18}
          />
        </div>
        <div>
          <span className="is-size-7 has-letter-spacing-1 is-align-self-center">
            {props.description}
          </span>
        </div>
      </div>
    </Link>
  )
}

export default BtnLeft
