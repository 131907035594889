import * as React from "react"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

const ShareButton = ({ location }) => {
  const [shareBtnState, setShareBtnState] = useState(false)

  function shareBtnToggle() {
    setShareBtnState(!shareBtnState)
  }

  return (
    <>
      <div className={`modal ${shareBtnState ? "is-active" : ""}`}>
        <div
          onClick={shareBtnToggle}
          className="modal-background close-modal"
        ></div>
        <div className="modal-content modal-share has-background-dark">
          <div className="columns mb-0 modal-columns is-gapless">
            <div className="column is-two-thirds has-text-centered is-flex is-relative">
              <StaticImage
                src="../images/modal-bg.jpg"
                alt="Obraz - Wojciech Rutkowski"
                objectFit="cover"
                objectPosition="center center"
                placeholder="blurred"
                style={{
                  height: "100%",
                  width: "100%",
                  zIndex: "10",
                  position: "absolute",
                  top: "0",
                  left: "zero",
                }}
              />
              <div className="modal-share-title is-size-5 has-letter-spacing-1 is-family-secondary">
                <p>UDOSTĘPNIJ</p>
                <p>STRONĘ</p>
              </div>
            </div>
            <div className="column has-text-centered is-align-self-center modal-share-icons">
              <div className="m-5">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://obrazyrutkowski.pl${location.pathname}`}
                  target="_blank"
                >
                  <div className="icon-share material-icons-outlined md-18">
                    facebook
                  </div>
                  <p className="is-size-7 has-text-weight-light has-letter-spacing-1">
                    NA FACEBOOKU
                  </p>
                </a>
              </div>
              <div className="m-5">
                <a
                  className=""
                  href={`mailto:?subject=Wojciech%20Rutkowski%20%20-%20artysta%20malarz&body=Poznaj%20obrazy%20Wojciecha%20Rutkowskiego:%20https%3A%2F%2Fobrazyrutkowski.pl${location.pathname}`}
                  target="_blank"
                >
                  <div className="icon-share material-icons-outlined md-18">
                    mail
                  </div>
                  <p className="is-size-7 has-text-weight-light has-letter-spacing-1">
                    E-MAILEM
                  </p>
                </a>
              </div>
            </div>
          </div>

          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={shareBtnToggle}
          ></button>
        </div>
      </div>

      <div className="is-flex is-justify-content-flex-end mb-1">
        <a className="ml-3 is-flex" onClick={shareBtnToggle}>
          <div className="material-icons-outlined md-18 has-opacity-9 is-align-self-center">
            share
          </div>
        </a>
      </div>
    </>
  )
}
export default ShareButton
