import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const GalleryCategoryTilesMobile = () => {
  return (
    <>
      <div className="subtitle is-6 is-family-secondary pt-6 mb-5 has-text-weight-light has-letter-spacing-1">
        DZIAŁY W GALERII
      </div>

      <div class="columns is-multiline is-mobile">
        <div class="column is-half">
          <Link
            to="/galeria/wiosna"
            className="has-text-centered is-flex is-relative mb-6"
          >
            <StaticImage
              src="../images/galeria-wiosna-ratio.jpg"
              alt="Galeria obrazów - Wiosna"
              objectFit="cover"
              objectPosition="center center"
              placeholder="blurred"
              style={{}}
            />
            <div className="content-in-img-title is-size-5 has-letter-spacing-1 is-family-secondary">
              <p>WIOSNA</p>
            </div>
          </Link>
        </div>

        <div class="column is-half">
          <Link
            to="/galeria/lato"
            className="has-text-centered is-flex is-relative mb-6"
          >
            <StaticImage
              src="../images/galeria-lato-ratio.jpg"
              alt="Galeria obrazów - Lato"
              objectFit="cover"
              objectPosition="center center"
              placeholder="blurred"
            />
            <div className="content-in-img-title is-size-5 has-letter-spacing-1 is-family-secondary">
              <p>LATO</p>
            </div>
          </Link>
        </div>

        <div class="column is-half">
          <Link
            to="/galeria/jesien"
            className="has-text-centered is-flex is-relative mb-6"
          >
            <StaticImage
              src="../images/galeria-jesien-ratio.jpg"
              alt="Galeria obrazów - Jesień"
              objectFit="cover"
              objectPosition="center center"
              placeholder="blurred"
              style={{}}
            />
            <div className="content-in-img-title is-size-5 has-letter-spacing-1 is-family-secondary">
              <p>JESIEŃ</p>
            </div>
          </Link>
        </div>
        <div class="column is-half">
          <Link
            to="/galeria/zima"
            className="has-text-centered is-flex is-relative mb-6"
          >
            <StaticImage
              src="../images/galeria-zima-ratio.jpg"
              alt="Galeria obrazów - Zima"
              objectFit="cover"
              objectPosition="center center"
              placeholder="blurred"
              style={{}}
            />
            <div className="content-in-img-title is-size-5 has-letter-spacing-1 is-family-secondary">
              <p>ZIMA</p>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default GalleryCategoryTilesMobile
